import React from 'react'
import { Hero } from '../components/Hero'
import Layout from '../components/Layout'
import CTASection from '../components/CTASection'
import Block from '../components/Block'
import Page from '../components/Page'
import styled from 'styled-components'
import Badge from '../assets/images/badges/soc-2-image.png'

const SecurityPage = () => {
  return (
    <Layout
      title="Security"
      description="We understand that security is critical when working with software development data. Hundreds of development teams trust us with their data, and we take our responsibility very seriously."
    >
      <Page>
        <Hero>
          <Block type="padding-small">
            <h1>Security</h1>
            <p className="text-large">
              We understand that security is critical when working with software
              development data. Hundreds of development teams trust us with
              their data, and we take our responsibility very seriously.
            </p>
          </Block>
        </Hero>
        <Block size="medium">
          <h2>SOC 2 Type 2</h2>
          <p>
            System and organization control (SOC) 2 is an audit designed to
            ensure that service providers have the internal controls in place to
            securely manage data and protect the interests and privacy of their
            customers.
          </p>
          <BadgeContainer>
            <img src={Badge} alt="SOC 2 badge" />
          </BadgeContainer>
          <p>
            An independent auditor has confirmed that we adhere to the highest
            industry standards when it comes to data security and privacy.
          </p>
          <p>
            If you&apos;re a Swarmia customer and want to learn more, you can
            reach out to your Customer Success Manager to get access to the full
            report. If you’re only just beginning to evaluate whether Swarmia
            might be the right solution for you, you can email us at{' '}
            <a href="mailto:hello@swarmia.com">hello@swarmia.com</a> to request
            your copy.
          </p>
          <h2>Data usage</h2>
          <p>
            The user and customer data we process is outlined in our{' '}
            <a href="https://swarmia.com/privacy/">Privacy Policy</a>. We also
            sign a GDPR-compatible Data Processing Agreement with our customers.
          </p>
          <p>
            In order to provide our service, we access metadata about your
            organization&apos;s activities in GitHub and Jira/Linear. This
            includes Pull Requests, Git commits, automated test runs and issues.
          </p>
          <p>
            We request permission to access source code but never store it. For
            each commit, we store the size of the change per file. This allows
            us to estimate the complexity of the change, and also to ignore
            changes to automatically generated files (such as{' '}
            <em>package-lock.json</em> or <em>Gemfile.lock</em>).
          </p>
          <p>
            Our stored data is physically located in Frankfurt, Germany. Some
            data (mainly names and emails) are shared with our subprocessors in
            accordance with GDPR, and they have data centers in the US.
          </p>
          <p>
            In case you want to delete your data from our service permanently,
            you can request it through customer support.
          </p>
          <h2>Production infrastructure</h2>
          <p>
            All customer data is encrypted{' '}
            <a href="https://cloud.google.com/security/encryption-in-transit">
              in transit
            </a>{' '}
            and{' '}
            <a href="https://cloud.google.com/security/encryption-at-rest/default-encryption">
              at rest
            </a>
            . Our load balancers use{' '}
            <a href="https://www.ssllabs.com/ssltest/analyze.html?d=app.swarmia.com&hideResults=on&latest">
              modern SSL/TLS best practices
            </a>
            .
          </p>
          <p>
            All production infrastructure is configured as code, meaning that it
            also goes through the same review process as the rest of the
            software. We use Google Cloud services for our production
            infrastructure.
          </p>
          <p>
            Most of our code runs in a Kubernetes cluster that&apos;s been
            hardened according to best practices. Each microservice has its own
            service account with restricted permissions, and containers are run
            with unprivileged user accounts.
          </p>
          <p>
            Our servers don&apos;t have public-facing IP addresses, and
            they&apos;re only reachable through the load balancer. All egress
            traffic goes through a set of known IP addresses using NAT.
          </p>
          <p>We do not commit secrets to repositories without encryption.</p>
          <h2>Application security</h2>
          <p>
            Security issues are treated with the highest priority. We use modern
            libraries for GraphQL APIs, database access, and user interfaces,
            minimizing most common web application security issues.
          </p>
          <p>
            We use the most common{' '}
            <a href="https://securityheaders.com/?q=https%3A%2F%2Fapp.swarmia.com%2F&hide=on">
              security-related HTTP headers
            </a>{' '}
            in our application.
          </p>
          <p>
            Our CI/CD pipeline includes static analysis tools for finding
            security issues early.
          </p>
          <p>
            We&apos;re automatically following updates to library dependencies,
            and even non-critical updates are generally applied weekly, after
            being reviewed by developers.
          </p>
          <h2>Logging and monitoring</h2>
          <p>
            All of our production systems have logs and monitoring. We pay
            attention to both daily, and on top of this we generate automatic
            alerts from anomalies. We use an incident response system to respond
            to alerts.
          </p>
          <h2>Disaster recovery</h2>
          <p>
            We have a documented business continuity and disaster recovery plan,
            which we review and test annually.
          </p>
          <p>
            We know how to recover our production environment in the unlikely
            event of a production disaster, and there are detailed instructions
            for doing this.
          </p>
          <p>
            This includes keeping backups of our production data, and monitoring
            the backup process. We also regularly test restoring production data
            from backups.
          </p>
          <h2>Principle of least privilege</h2>
          <p>
            We apply <em>principle of least privilege</em> in all areas: asking
            only for permissions we absolutely need, giving employees access to
            systems they need for doing their job, and restricting service
            accounts of our microservices.
          </p>
          <h2>Development workflow</h2>
          <p>
            We use a systematic approach to software development where changes
            to customer impacting services are reviewed, tested, approved, and
            well communicated.
          </p>
          <p>
            We develop features in a development environment segregated from the
            production environment. We do not use customer data in test and
            development environments.
          </p>
          <p>
            All code is stored in version control (GitHub). All code changes are
            reviewed by another developer before merging to the master branch.
            Our CI/CD pipeline handles deployments to production automatically
            after each change.
          </p>
          <h2>Security audits</h2>
          <p>
            We audit our security practices and our application every year in
            December and in June based on OWASP ASVS 4.0. The results of the
            latest audit are available on request.
          </p>
          <p>
            Security has been our focus since the very beginning, and we did our
            first security review only one month after starting the development.
          </p>
          <h2>Employees</h2>
          <p>
            Employees are trained to treat customer data with care. This
            includes:
          </p>
          <ul>
            <li>Encrypting disks on their laptops</li>
            <li>
              Using a password manager and a different strong password for each
              service
            </li>
            <li>
              Using two-factor authentication for all work-related services
            </li>
            <li>Not storing customer data on their own computers</li>
            <li>
              Identifying typical security risks, such as phishing attacks
            </li>
          </ul>
          <p>Employee onboarding and offboarding follows a checklist.</p>
          <p>
            Security competency is a part of the hiring process for software
            engineers.
          </p>
          <h2>Informing customers about security incidents</h2>
          <p>
            We inform customers about security incidents in accordance with the
            GDPR.
          </p>
        </Block>
      </Page>
      <CTASection>
        Ready to accelerate? Join&nbsp;the&nbsp;1,000+ teams using Swarmia
        today.
      </CTASection>
    </Layout>
  )
}

const BadgeContainer = styled.div`
  margin: 2rem auto;
  img {
    max-width: 480px;
  }
  @media screen and (max-width: 520px) {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
`

export default SecurityPage
